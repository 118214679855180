import React from 'react'
import {
  Background,
  Button,
  ComponentWrapper,
  Row,
  Column,
  TextButton,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import redirectUrl from 'utils/redirect-url'
import { useCustomNav } from 'utils/customNavigate'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { RedirectSpacer } from 'pages/verify-identity/verify-identity'

const RegisterLiquidComplete = () => {
  const navigator = useCustomNav()
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background
            src={
              'https://res.cloudinary.com/stokr/image/upload/f_auto,q_auto/v1677599533/Static/Artboard_7_3_4x_pvpb3y.png'
            }
            mobileRatio="16:9"
            contain
            withPadding
          />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        {!redirectUrl ? (
          <ComponentWrapper noPaddingVertical>
            <TextButton onClick={() => navigator.navigate('/taxid')}>
              Back to tax id
            </TextButton>
          </ComponentWrapper>
        ) : (
          <RedirectSpacer />
        )}

        <ComponentWrapper>
          <Text>
            <h1>Congratulations!</h1>
            <p>
              You have successfully registered your liquid address on STOKR.
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              if (redirectUrl) {
                deleteRedirectCookieAndNavigate()
              } else {
                navigator.navigate('/risk-questionnaire')
              }
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RegisterLiquidComplete
