/* Libraries */
import axios from 'axios'

const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://platform-api.stokr.io/api/v1/'

export default axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: BASE_URL,
})
