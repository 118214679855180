import React from 'react'
import { useCustomNav } from 'utils/customNavigate'
import {
  Background,
  Button,
  TextButton,
  ComponentWrapper,
  Row,
  Column,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import ToDoTask from 'components/ToDoList/ToDoListTask'
import { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import redirectUrl from 'utils/redirect-url'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { RedirectSpacer } from 'pages/verify-identity/verify-identity'

const RiskQuestionnaireCompletePage = ({ setShowReview }) => {
  const navigator = useCustomNav()
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        {!redirectUrl ? (
          <ComponentWrapper noPaddingVertical>
            <TextButton
              onClick={() => navigator.navigate('/register-liquid-securities')}
            >
              Back to liquid address
            </TextButton>
          </ComponentWrapper>
        ) : (
          <RedirectSpacer />
        )}

        <ComponentWrapper>
          <Text>
            <h1>RISKS CONSIDERATIONS REVIEWED</h1>
            <p>
              Great! You've taken a first step understanding investment risks
              better.
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <ToDoTask
            title="Risk Questionnaire"
            message="You have read and understood the risks"
            state={ToDoTaskState.APPROVED}
            icon={iconsMap.check}
          />
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              if (redirectUrl) {
                deleteRedirectCookieAndNavigate()
              } else {
                window.open('https://dashboard.stokr.io', '_self')
              }
            }}
          >
            Continue
          </Button>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <TextButton
            onClick={() => {
              setShowReview(true)
            }}
            data-cy="reviewAgain"
          >
            Review Again
          </TextButton>
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RiskQuestionnaireCompletePage
