//import 'babel-polyfill'
import U2F from '@ledgerhq/hw-transport-u2f'
import Ledger from '@ledgerhq/hw-app-eth'
import algosdk from 'algosdk'
import Config from './StokrWeb3Config'
import { isMobile, isChrome, isFirefox } from 'react-device-detect'
import MyAlgo from '@randlabs/myalgo-connect'
import fetchData from './fetchData'

// Standard Algod credentials in the Sandbox
const algodToken =
  'd7ac4a580122b6dd0ceea43db07b5dfd0daf85ffac489b6e57a7e5ab67424207'
const algodServer = 'https://algorand.stokr.io'
const algodPort = '443'

const algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort)

const myAlgoWallet = new MyAlgo()

export const getEtherBalance = async (addresses) => {
  let result = await fetchData('wallets/tokenBalances')

  return result
}

export const getEtherPrice = async () => {
  return await fetchData('investment/etherprice')
}

// Metamask Section

export const isMetamaskInstalled = () => {
  if (!window.ethereum) return false
  //if (!window.ethereum.isMetaMask) return false

  return true
}

export const getMetamaskAddress = async () => {
  try {
    const accounts = await window.ethereum.enable()

    return accounts[0]
  } catch (error) {
    return error
  }
}

//AlgoSigner Section
export const isAlgoSignerInstalled = () => {
  if (typeof window.AlgoSigner !== 'undefined') return true
  return false
}

export const getAlgoSignerAddress = async (ledger) => {
  try {
    await window.AlgoSigner.connect({
      ledger: ledger,
    })
    const acc = await window.AlgoSigner.accounts({
      ledger: ledger,
    })
    const address = acc //JSON.stringify(acc, null, 2)
    return address
  } catch (error) {
    return error
  }
}

export const getMyAlgoWalletAddress = async () => {
  try {
    const accounts = await myAlgoWallet.connect()

    const addresses = accounts.map((account) => account.address)
    return addresses
  } catch (error) {
    return error
  }
}

// Ledger Section

export const supportsU2F = () => {
  return isChrome && isMobile === false
}

const getLedgerHandle = async () => {
  const transport = await U2F.create(
    Config.ledger.openTimeout,
    Config.ledger.listenTimeout,
  )
  return new Ledger(transport)
}

export const isLedgerAvailable = async () => {
  const ledger = await getLedgerHandle()
  const path = `/m/44'/60'/0'/0/0`

  try {
    await ledger.getAddress(path, false, false)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

let pool
let counter

const getSingleLedgerAddress = async (ledger, path, id) => {
  const single = await ledger.getAddress(path, false, false)

  const data = {
    address: single.address,
    //ether: ether,
    //euro: euro,
    path: path,
    title: `Ethereum ${id}`,
    id: `address${id}`,
    radioId: `eth ${id}`,
  }

  return data
}

export const getLedgerAddresses = async (depth = Config.ledger.scanDepth) => {
  pool = []
  counter = 0

  const ledger = await getLedgerHandle()

  try {
    for (let i = 0; i < depth; i++) {
      const keyDerivationPath = `/m/44'/60'/0'/${i}/0`
      const legacyKeyDerivationPath = `/m/44'/60'/0'/${i}`

      pool.push(
        await getSingleLedgerAddress(ledger, keyDerivationPath, ++counter),
      )
      pool.push(
        await getSingleLedgerAddress(
          ledger,
          legacyKeyDerivationPath,
          ++counter,
        ),
      )
    }
    const ethData = await getEtherBalance(pool.map((item) => item.address))
    //const ethPrice = await getEtherPrice()
    //console.log('🚀 ~ ethPrice', ethPrice)

    for (const item of pool) {
      item.ether = ethData.etherBalance.byInvestorAddress[item.address]
      item.euro = item.ether * ethData.currentEtherPrice
    }

    // top balance > 0
    pool.sort((a, b) => (a.ether < b.ether ? 1 : -1))

    return pool
  } catch (error) {
    console.log(error)
  }
}

export const showUnsupported = () => {
  // if we have a window.ethereum object,
  // we don't show the unsupported page
  if (window.ethereum) return

  if (isFirefox || window.chrome) return

  // if we have a mobile device,
  // we show the unsupported page
  if (isMobile)
    return "Unfortunately we currently don't support investments on mobile devices."

  // Default in case none of the above checks are truthy
  return "Oops. We noticed that Ethereum address registration isn't supported on the browser you're using. Log in on a desktop using a browser supporting your wallet. See more below in FAQs."

  // if we have a supported browser,
  // we don't show the unsupported page
  // if (isFirefox) return 'Please install the MetaMask add-on.'

  // if (window.chrome)
  //   return 'Please install the MetaMask extension or use your Ledger.'
}

export const makeOptInTx = async (
  assetId,
  senderAddress,
  receiverAddress,
  wallet,
  ledger = 'TestNet',
) => {
  //fetch the grouped tx from backend
  let transactions = await fetchData('wallets/createOptinTx', {
    account: senderAddress,
    assetId: Number(assetId),
  })

  //make Uint8Array from fetched tx
  let convertedTx = transactions.map((x) => new Uint8Array(Object.values(x)))

  //sign the opt-in transaction with wallet
  if (wallet === 'algosigner') {
    // Use the AlgoSigner encoding library to make the transactions base64
    const OptinTx_base64 = window.AlgoSigner.encoding.msgpackToBase64(
      convertedTx[2],
    )
    const FaucetTx_base64 = window.AlgoSigner.encoding.msgpackToBase64(
      convertedTx[0],
    )

    //sign tx
    let signedOptin = await window.AlgoSigner.signTxn([
      { txn: FaucetTx_base64, signers: [] },
      { txn: OptinTx_base64 },
    ])

    // Get the decoded binary Uint8Array values from the blobs
    const decoded_1 = convertedTx[1] // already signed and encoded
    const decoded_2 = new Uint8Array(
      atob(signedOptin[1].blob)
        .split('')
        .map((x) => x.charCodeAt(0)),
    )

    // Use their combined length to create a 3rd array
    let combined_decoded_txns = new Uint8Array(
      decoded_1.byteLength + decoded_2.byteLength,
    )

    // Starting at the 0 position, fill in the binary for the first object
    combined_decoded_txns.set(new Uint8Array(decoded_1), 0)

    // Starting at the first object byte length, fill in the 2nd binary value
    combined_decoded_txns.set(new Uint8Array(decoded_2), decoded_1.byteLength)

    // Modify our combined array values back to an encoded 64bit string
    const grouped_txns = btoa(
      String.fromCharCode.apply(null, combined_decoded_txns),
    )

    //send tx using algosigner
    await window.AlgoSigner.send({
      ledger: ledger,
      tx: grouped_txns,
    })
  } else {
    let signedOptin = await myAlgoWallet.signTransaction(convertedTx[2])

    //put only byte array to grouped tx
    let groupedTx = [convertedTx[1]]
    groupedTx.push(signedOptin.blob)

    await algodClient.sendRawTransaction(groupedTx).do()
  }
  return true
}
