import React, { useContext } from 'react'
import {
  Background,
  Button,
  TextButton,
  FAQ,
  ComponentWrapper,
  SectionTitle,
  Row,
  Column,
  CryptoAddress,
  AuthContext,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import backgroundImage from 'static/images/covers/rocket.png'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import ToDoTask from 'components/ToDoList/ToDoListTask'
import { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import { DisplayOnBreakpoint } from 'styles/rwd'

const RegisterAlgorand = ({ setShowFlow }) => {
  const { user } = useContext(AuthContext)
  const { investor_algo_addresses } = user

  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <ComponentWrapper>
          <Text>
            <h1>REGISTER ALGORAND ADDRESS</h1>
            <p>
              To invest in offerings that are supported by the Algorand
              blockchain network, you will need an Algorand address location for
              us to deposit your tokens.
            </p>

            {investor_algo_addresses ? (
              <>
                <p>
                  You already registered the following{' '}
                  {investor_algo_addresses.length > 1 ? 'addresses' : 'address'}
                  :{' '}
                </p>
                {investor_algo_addresses.map((address, i) => (
                  <CryptoAddress
                    key={address.address}
                    title={address.name}
                    address={address.address}
                    noBody
                    noBorderBottom={investor_algo_addresses.length === i + 1}
                    wrapped
                    borderBottom
                  />
                ))}
              </>
            ) : (
              <p>
                No address yet? No worries, we can guide you through the
                process.
              </p>
            )}
          </Text>
        </ComponentWrapper>

        {investor_algo_addresses && (
          <ComponentWrapper noPaddingTop>
            <ToDoTask
              title={`Algorand ${
                investor_algo_addresses.length > 1 ? 'Addresses' : 'Address'
              }`}
              message={`Your ${
                investor_algo_addresses.length > 1
                  ? 'addresses are'
                  : 'address is'
              } whitelisted`}
              state={ToDoTaskState.APPROVED}
              icon={iconsMap.check}
              notificationCount={investor_algo_addresses.length}
              greenNotification
            />
          </ComponentWrapper>
        )}

        <ComponentWrapper noPaddingTop>
          <Button
            onClick={() => {
              setShowFlow(true)
            }}
          >
            {investor_algo_addresses
              ? 'ADD ALGO ADDRESS'
              : 'REGISTER ALGO ADDRESS'}
          </Button>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <TextButton
            onClick={() => {
              deleteRedirectCookieAndNavigate()
            }}
          >
            I'll do this later
          </TextButton>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: "I don't have an Algorand address, what now?",
                content:
                  "Don't fret! Registering an account is easy. There are two methods to do so, with Algowallet or Algosign and they are both pretty straightforward. It can be done in minutes, just follow the steps!",
              },
              {
                title: 'What is Algorand?',
                content:
                  'Algorand is a blockchain based cryptocurrency network. It supports smart contract functionality and focuses its functionality based on the new and improved proof-of-stake principles rather than proof-of-work which most cryptocurrencies are currently based on.',
              },

              {
                title: "What if I'd like to do this later?",
                content:
                  'You can always skip this step and complete it from the Checklist in your Investor Dashboard at a later point in time. However, you will not be able to invest in any Algorand-based projects before doing so.',
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default RegisterAlgorand
