import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import { useCustomNav } from 'utils/customNavigate'
import {
  Button,
  Row,
  Column,
  Background,
  ComponentWrapper,
} from '@stokr/components-library'

import backgroundImage from 'static/images/covers/rocket.png'
import { useLocation } from 'react-router'
import { DisplayOnBreakpoint } from 'styles/rwd'

const Oops = ({}) => {
  const navigator = useCustomNav()
  const { state } = useLocation
  return (
    <Layout signupFlow>
      <SEO title={`Oops...`} />
      <Row main>
        <Column part={8} mainFixed>
          <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
            <Background src={backgroundImage} mobileRatio="16:9" />
          </DisplayOnBreakpoint>
        </Column>

        <Column part={8} withBorder mainRelative>
          <ComponentWrapper>
            <Text>
              <h3>OOPS...</h3>

              {state && state.message ? (
                <p>{state.message}</p>
              ) : (
                <p>Something went wrong, please start again.</p>
              )}
            </Text>
          </ComponentWrapper>

          {state && state.hasActivationTokenExpired ? (
            <ComponentWrapper>
              <Button
                onClick={() =>
                  (window.location.href = 'https://signup.stokr.io/')
                }
              >
                BACK TO BEGINNING
              </Button>
            </ComponentWrapper>
          ) : (
            <ComponentWrapper>
              <Button onClick={() => navigator.navigate('/')}>
                BACK TO BEGINNING
              </Button>
            </ComponentWrapper>
          )}
        </Column>
      </Row>
    </Layout>
  )
}

export default Oops
