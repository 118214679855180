import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useCustomNav } from 'utils/customNavigate'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form, { FormField, FormError } from 'components/Form/Form'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Button,
  Background,
  Checkbox,
  ComponentWrapper,
  Row,
  Column,
  AuthContext,
} from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import { CheckboxContext } from 'context/CheckboxContext'
import { DisplayOnBreakpoint } from 'styles/rwd'
import parse from 'html-react-parser'

const ButtonStyled = styled(Button)`
  min-width: 305px;
  margin: 10px 0;

  &:first-of-type {
    margin-right: 30px;
  }
`
const TermsStyled = styled.span`
  cursor: pointer;
  font-size: 12px;
  /* margin-left: 4px; */
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`

const WelcomePage = () => {
  const { user } = useContext(AuthContext)
  const { checkCheckbox, getCheckbox } = useContext(CheckboxContext)
  const { country, entity_name, emailVerified } = user
  const navigator = useCustomNav()
  const [termsCheckbox, settermsCheckbox] = useState({
    agreementText:
      "By creating this account, I agree with STOKR's Terms of Use",
    _id: 'terms',
  })

  useEffect(() => {
    if (!emailVerified) {
      navigator.navigate('/resend-activation-email')
    } else if (country) {
      navigator.navigate('/verify-identity')
    } else if (entity_name) {
      navigator.navigate('/taxid')
    } else {
      fecthCheckbox()
    }
  }, [])

  const fecthCheckbox = async () => {
    try {
      const result = await getCheckbox({ labels: ['signUpTerms'] })
      if (result?.signUpTerms) {
        settermsCheckbox(result.signUpTerms)
      }
    } catch (error) {
      console.log('🚀 ~ file: welcome.js:56 ~ error:', error)
    }
  }

  const initialValues = {
    terms: false,
  }

  const validationSchema = Yup.object().shape({
    terms: Yup.bool().oneOf([true], 'Please agree to continue '),
  })

  const onTermsClicked = () => {
    navigator.navigate('/terms', { state: { termsData: termsCheckbox } })
  }

  return (
    <Layout
      signupFlow
      signupHandler={() => {
        navigator.navigate('/signup')
      }}
    >
      <SEO title="Welcome" keywords={['gatsby', 'application', 'react']} />
      <Row main>
        <Column part={8} mainFixed>
          <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
            <Background src={backgroundImage} mobileRatio="16:9" />
          </DisplayOnBreakpoint>
        </Column>

        <Column part={8} mainRelative>
          <ComponentWrapper>
            <Text>
              <h1>{'WELCOME TO STOKR'}</h1>
              <p>{"You're logged in and ready to begin."}</p>
              <p>
                {
                  'Before you can start investing a few more steps need to be taken. '
                }
              </p>
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                // onFormSend(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => {
                const submitDisabled = !values.terms

                return (
                  <Form>
                    <ComponentWrapper
                      noPaddingTop
                      noPaddingBottom
                      noPaddingHorizontal
                    >
                      <FormField>
                        <Checkbox
                          id={termsCheckbox._id}
                          name="terms"
                          text={parse(termsCheckbox.agreementText, {
                            replace: (domNode) => {
                              //change <u> tag to <termsStyled> tag and add onClick event
                              if (
                                domNode.type === 'tag' &&
                                domNode.name === 'u'
                              ) {
                                return (
                                  <TermsStyled
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      onTermsClicked()
                                    }}
                                  >
                                    {domNode.children[0]?.data}
                                  </TermsStyled>
                                )
                              }
                            },
                          })}
                          value="yes"
                          checked={!!values.terms}
                          onChange={async (e) => {
                            handleChange(e)
                            if (e.target.checked) {
                              await checkCheckbox({
                                userId: user._id,
                                checkboxId: e.target.id,
                              })
                            }
                          }}
                          onBlur={handleBlur}
                          error={errors.terms && touched.terms}
                          touched={!!touched.terms}
                        />
                        <FormError
                          show={errors.terms && touched.terms}
                          withMarginLeft
                        >
                          {errors.terms}
                        </FormError>
                      </FormField>
                    </ComponentWrapper>

                    <ComponentWrapper noPaddingHorizontal>
                      <ButtonStyled
                        disabled={submitDisabled}
                        onClick={() =>
                          navigator.navigate('/country-of-residence')
                        }
                      >
                        REGISTER AS AN INDIVIDUAL
                      </ButtonStyled>

                      <ButtonStyled
                        disabled={submitDisabled}
                        onClick={() => navigator.navigate('/register-entity')}
                      >
                        REGISTER A LEGAL ENTITY
                      </ButtonStyled>
                    </ComponentWrapper>
                  </Form>
                )
              }}
            </Formik>
          </ComponentWrapper>
        </Column>
      </Row>
    </Layout>
  )
}

export default WelcomePage
