import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import {
  StokrLoader,
  LoginWithOTPFlow,
  AuthProvider,
  AuthConsumer,
} from '@stokr/components-library'
import routes from './routes/index'
import CheckboxProvider from 'context/CheckboxContext'

const createHistory = require('history').createBrowserHistory

export const history = createHistory()

const App = () => {
  const navigate = useNavigate()

  //specific logic for signup flow in get user
  const customUserValidate = (user) => {
    const { country, emailVerified } = user

    if (country && !user.countryObj?.isAllowed) {
      navigate('/country-of-residence')
    } else if (!emailVerified) {
      navigate('/resend-activation-email')
    }
  }

  return (
    <AuthProvider customValidateGetUser={customUserValidate}>
      <AuthConsumer>
        {({ isFetchingUser, user }) =>
          user ? (
            <CheckboxProvider>
              <Routes history={history}>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </CheckboxProvider>
          ) : isFetchingUser ? (
            <StokrLoader />
          ) : (
            <LoginWithOTPFlow withBackground />
          )
        }
      </AuthConsumer>
    </AuthProvider>
  )
}

export default App
